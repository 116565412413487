import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 0 auto 98px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 0 auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;
  height: auto;

  ${media.mobile} {
    width: 70px;
    right: 50px;
    top: -2px;
  }
  ${media.tablet} {
    width: 120px;
    right: 60px;
    top: -20px;
  }
`

const MiddleImage = styled(TopImage)`
  transform: translate(-50%, 0);

  ${media.mobile} {
    top: 50px;
    left: 2px;
    width: 45px;
  }
  ${media.tablet} {
    top: 20%;
    left: 2px;
    width: 87px;
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 50%);
    bottom: 20px;
    left: auto;
    right: 0;
    width: 45px;
  }
  ${media.tablet} {
    transform: translate(-50%, 50%);
    bottom: -55%;
    right: auto;
    left: 50%;
    width: 89px;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lemonPeel: file(relativePath: { regex: "/lemon-peel.png/" }) {
      childImageSharp {
        fluid(maxWidth: 115) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    gelatin: file(relativePath: { regex: "/ingredient-orbs/gelatin.png/" }) {
      childImageSharp {
        fluid(maxWidth: 101) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    orangePeel: file(relativePath: { regex: "/orange-peel.png/" }) {
      childImageSharp {
        fluid(maxWidth: 87) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const grummiesMaxCharacters = 113
  const othersMaxCharacters = 122

  const copy = {
    grummies:
      'We use vegan pectin from citrus and apple peels as the essential core base of our Grummies – we never use gelatin.\n\nPectin is what gives our Grummies their mass and consistency.  We use water to hydrate the pectin, so it doesn’t clump.  Organic tapioca syrup also helps form the base.',
    others:
      'You may have heard that a lot of gummies aren’t vegan, and it’s absolutely true. Most top retail gummies use cheap gelatin from cows and pigs to form their base.\n\nPETA’s definition of gelatin is “a protein obtained by boiling skin, tendons, ligaments, and/or bones with water.” Gross, no thank you.',
  }

  const displayCopy = {
    grummies: open ? copy.grummies : `${copy.grummies.slice(0, grummiesMaxCharacters)}...`,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient={false} />
      <Container>
        <H1>Gummy base</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={370}
          openHeight={500}
          animationKey="gummyBase"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.lemonPeel.childImageSharp.fluid} aria-label="hidden" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.gelatin.childImageSharp.fluid} aria-label="hidden" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.orangePeel.childImageSharp.fluid} aria-label="hidden" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>

              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
