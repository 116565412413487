import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 0 auto 98px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 0 auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;
  height: auto;

  ${media.mobile} {
    width: 50px;
    right: 50px;
    top: -10px;
  }
  ${media.tablet} {
    width: 75px;
    right: 60px;
    top: -16px;
  }
`

const MiddleImage = styled(TopImage)`
  transform: translate(-50%, 0);

  ${media.mobile} {
    top: 50px;
    left: 0;
    width: 40px;
  }
  ${media.tablet} {
    top: 20%;
    left: 2px;
    width: 64px;
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 50%);
    bottom: 20px;
    width: 50px;
    left: auto;
    right: 0;
  }
  ${media.tablet} {
    transform: translate(-50%, 50%);
    bottom: -55%;
    right: auto;
    left: 50%;
    width: 118px;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    strawberry: file(relativePath: { regex: "/strawberry.png/" }) {
      childImageSharp {
        fluid(maxWidth: 115) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    apricot: file(relativePath: { regex: "/apricot.png/" }) {
      childImageSharp {
        fluid(maxWidth: 101) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    blueberry: file(relativePath: { regex: "/blueberry.png/" }) {
      childImageSharp {
        fluid(maxWidth: 87) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const grummiesMaxCharacters = 62
  const othersMaxCharacters = 112

  const copy = {
    grummies:
      'We only use organic natural flavors from fruits and vegetables.\n\nThat’s it. Nothing else.',
    others:
      'Beware of juice concentrates. These are sugar-stuffed juice powders that provide the “flavoring” in many leading retail gummies.\n\nIt’s like the difference between drinking a juice you squeezed from fresh oranges, and a “juice” that came from a crystalline powder in a huge vat stuffed with preservatives.',
  }

  const displayCopy = {
    grummies: open ? copy.grummies : `${copy.grummies.slice(0, grummiesMaxCharacters)}...`,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient />
      <Container>
        <H1>Flavor</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={330}
          openHeight={490}
          animationKey="flavor"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.strawberry.childImageSharp.fluid} aria-label="hidden" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.apricot.childImageSharp.fluid} aria-label="hidden" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.blueberry.childImageSharp.fluid} aria-label="hidden" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>
              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
