import React, { useState, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import { gsap, Power4 } from 'gsap'

import { getScreenType, media } from '../../styles/util'

import PlusCircleIcon from '../../assets/img/icons/plus-circle.svg'
import MinusCircleIcon from '../../assets/img/icons/minus-circle.svg'

const Grid = styled('div')<{ isMobile?: boolean }>`
  position: relative;

  width: 100%;
  display: grid;

  ${media.mobile} {
    grid-template-columns: 3px 1fr 3px;
    padding: 0 28px;
    display: ${props => (props.isMobile ? 'grid' : 'none')};
  }
  ${media.tablet} {
    grid-template-columns: 3px 1fr 3px 1fr 3px;
    display: ${props => (props.isMobile ? 'none' : 'grid')};
  }
`

const Column = styled('div')<{ startHeight: number }>`
  position: relative;

  width: 100%;
  transition: 1s ease-in-out all;
  display: grid;
  grid-template-rows: 3px 1fr 3px;
  justify-items: center;
  padding: 16px 16px 16px 16px;

  ${media.tablet} {
    min-height: ${props => props.startHeight}px;
  }

  &:last-child {
    padding: 16px 16px 30px 16px;
  }
`

const MobileColumnOtherGummies = styled(Column)`
  padding-top: 0;
  grid-template-rows: 1fr 3px;

  & h2 {
    padding-top: 16px;
  }
`

const GradientCol = styled.div`
  transition: 1s ease-in-out all;
  height: calc(100% - 80px);
  width: 100%;
  background: linear-gradient(360deg, #fa6666 9.07%, #f090c3 37.37%, #52d9bf 69.31%, #21a0f8 96.7%);
  border-radius: 10px;
  margin-top: 40px;
`

const MiddleGradientCol = styled(GradientCol)`
  height: calc(100% - 80px);
  margin: 40px 0 0 0;
`

const PurpleCol = styled(GradientCol)`
  background: ${props => props.theme.colors.lightPurple};

  ${media.mobile} {
    height: calc(100% - 50px);
    margin: 10px 0 0 0;
  }

  ${media.tablet} {
    height: calc(100% - 80px);
    margin: 40px 0 0 0;
  }
`

const GradientRow = styled(GradientCol)`
  height: 100%;
  width: calc(100% - 26px);
  background: linear-gradient(90deg, #fa6666 2.02%, #f090c3 39.76%, #52d9bf 68.32%, #21a0f8 99.94%);
  margin: 0 16px 0 16px;
`

const PurpleRow = styled(GradientRow)`
  background: ${props => props.theme.colors.lightPurple};
`

const BottomGradientRow = styled(GradientRow)`
  background: linear-gradient(
    270deg,
    #fa6666 2.02%,
    #f090c3 39.76%,
    #52d9bf 68.32%,
    #21a0f8 99.94%
  );
`

const ColContent = styled('div')`
  width: 100%;
  margin: 0 auto;
  display: grid;
  justify-items: center;
  text-align: center;

  & > h2 {
    white-space: pre-line;
    align-self: center;
  }

  & > p {
    white-space: pre-line;
  }

  ${media.mobile} {
    grid-template-rows: 50px auto;
    grid-row-gap: 14px;
    padding: 14px 13px 40px 13px;

    & > h2 {
      font-size: 16px;
      line-height: 18px;
    }
    & > p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  ${media.tablet} {
    height: 100%;
    grid-template-rows: 85px auto;
    grid-row-gap: 20px;
    padding: 34px 36px 45px 36px;

    & > h2 {
      font-size: 25px;
      line-height: 32px;
    }
    & > p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const MobileColContentOtherGummies = styled(ColContent)`
  ${media.mobile} {
    padding-top: 0;
  }
`

const IconImg = styled('img')<{ active?: boolean }>`
  box-sizing: content-box;
  border-radius: 50%;
  width: auto;
  cursor: pointer;
  position: absolute;

  transition: 500ms ease all;
  z-index: 10;
  padding: 10px;

  ${media.mobile} {
    transform: translate(-50%, -50%) ${props => (props.active ? 'rotate(-180deg)' : '')};
    height: 40px;
    left: 50%;
    top: -16px;
  }
`

interface GridGroupInterface {
  animationKey: string
  leftContent: ReactNode
  rightContent: ReactNode
  open: boolean
  setOpen: (prevState: any) => any
  closedHeight: number
  openHeight: number
}

interface ComponentInterface {
  animationKey: string
  leftContent: ReactNode
  rightContent: ReactNode
  handleClick?: () => void
  iconOpen?: boolean
  startHeight: number
}

const DesktopGridGroup: React.FC<ComponentInterface> = ({
  startHeight,
  leftContent,
  rightContent,
  animationKey,
}) => (
  <Grid className={`whats-inside__grid-group--${animationKey}`}>
    <GradientCol />

    <Column startHeight={startHeight}>
      <GradientRow />
      <ColContent>{leftContent}</ColContent>
      <BottomGradientRow />
    </Column>

    <MiddleGradientCol />

    <Column startHeight={startHeight}>
      <PurpleRow />
      <ColContent>{rightContent}</ColContent>
      <PurpleRow />
    </Column>

    <PurpleCol />
  </Grid>
)

const MobileGridGroup: React.FC<ComponentInterface> = ({
  startHeight,
  leftContent,
  rightContent,
  animationKey,
  handleClick,
  iconOpen,
}) => {
  return (
    <Grid className={`whats-inside__grid-group--${animationKey}`} isMobile>
      <GradientCol />
      <Column startHeight={startHeight}>
        <GradientRow />
        <ColContent>{leftContent}</ColContent>
        <BottomGradientRow />
      </Column>
      <GradientCol />

      <PurpleCol />
      <MobileColumnOtherGummies startHeight={startHeight}>
        <IconImg
          src={!iconOpen ? PlusCircleIcon : MinusCircleIcon}
          alt="Toggle icon"
          onClick={handleClick}
          active={iconOpen}
        />

        <MobileColContentOtherGummies>{rightContent}</MobileColContentOtherGummies>
        <PurpleRow />
      </MobileColumnOtherGummies>
      <PurpleCol />
    </Grid>
  )
}

const GridGroup: React.FC<GridGroupInterface> = ({
  leftContent,
  rightContent,
  animationKey,
  open,
  setOpen,
  closedHeight,
  openHeight,
}) => {
  const [iconOpen, setIconOpen] = useState(false)

  const screenType = getScreenType()
  useEffect(() => {
    if (screenType === 'desktop' || screenType === 'tablet') setOpen(true)
  }, [screenType])

  const handleClick = () => {
    const tl = gsap.timeline()
    if (open) {
      setIconOpen(false)
      tl.call(() => setOpen((prevState: any) => !prevState))
      tl.to(`.whats-inside__grid-group--${animationKey}`, {
        height: closedHeight,
        duration: 1,
        ease: Power4.easeOut,
      })
    } else {
      setIconOpen(true)
      tl.to(`.whats-inside__grid-group--${animationKey}`, {
        height: openHeight,
        duration: 0.5,
        ease: Power4.easeOut,
      })
      tl.call(() => setOpen((prevState: any) => !prevState))
    }
  }

  return (
    <>
      <DesktopGridGroup
        leftContent={leftContent}
        rightContent={rightContent}
        animationKey={animationKey}
        iconOpen={iconOpen}
        handleClick={handleClick}
        startHeight={closedHeight}
      />
      <MobileGridGroup
        leftContent={leftContent}
        rightContent={rightContent}
        animationKey={animationKey}
        iconOpen={iconOpen}
        handleClick={handleClick}
        startHeight={closedHeight}
      />
    </>
  )
}

export default GridGroup
