import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../../styles/util'

const Container = styled.div`
  width: 100%;
  max-width: 904px;

  ${media.mobile} {
    margin: 0px auto 40px auto;
  }
  ${media.tablet} {
    margin: 30px auto 97px auto;
  }
`

const query = graphql`
  query {
    image: file(relativePath: { regex: "/whats-inside/bottom-section.png/" }) {
      childImageSharp {
        fluid(maxWidth: 905) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)

  return (
    <Container>
      <Img fluid={data.image.childImageSharp.fluid} alt="Grummies are grood for you" />
    </Container>
  )
}
