import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 0 auto 98px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 0 auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;

  ${media.mobile} {
    width: 40px;
    height: auto;
    right: 60px;
    top: 0px;
  }
  ${media.tablet} {
    width: 75px;
    height: auto;
    right: 60px;
    top: -16px;
  }
`

const MiddleImage = styled(TopImage)`
  transform: translate(-50%, 0);
  ${media.mobile} {
    top: 60px;
    left: 0;
    width: 45px;
  }
  ${media.tablet} {
    top: 20%;
    left: 2px;
    width: 87px;
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 50%);
    bottom: 50px;
    left: auto;
    right: 0;
    width: 40px;
  }
  ${media.tablet} {
    transform: translate(-50%, 50%);
    bottom: -50%;
    left: 50%;
    right: auto;
    width: 78px;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    glucoseSyrup: file(relativePath: { regex: "/glucose-syrup.png/" }) {
      childImageSharp {
        fluid(maxWidth: 115) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cornSyrup: file(relativePath: { regex: "/corn-syrup.png/" }) {
      childImageSharp {
        fluid(maxWidth: 101) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stevia: file(relativePath: { regex: "/stevia.png/" }) {
      childImageSharp {
        fluid(maxWidth: 87) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const grummiesMaxCharacters = 108
  const othersMaxCharacters = 98

  const copy = {
    grummies:
      'By definition, sweeteners are required to create a gummy. What you use, however, makes a world of difference.\n\nWe only use organic tapioca syrup and organic cane sugar. These two ingredients give our Grummies a light, clean and subtle sweetness.',
    others:
      'Most gummies on the market are stuffed with sugar from over-processed corn syrup or glucose syrup.\n\nHealthline.com states “consuming glucose syrup regularly may increase your risk of obesity, high blood sugar, poor dental health, high blood pressure, and heart disease”.',
  }

  const displayCopy = {
    grummies: open ? copy.grummies : `${copy.grummies.slice(0, grummiesMaxCharacters)}...`,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient />
      <Container>
        <H1>Sweeteners</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={330}
          openHeight={480}
          animationKey="sweeteners"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.glucoseSyrup.childImageSharp.fluid} alt="No glucose syrup" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.cornSyrup.childImageSharp.fluid} alt="No corn syrup" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.stevia.childImageSharp.fluid} alt="No stevia" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>

              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
