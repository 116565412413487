import React from 'react'
import styled from 'styled-components'

import { media } from '../../styles/util'

const TitleGradient = styled('div')<{ hasDesktopGradient: boolean }>`
  width: 100%;
  position: absolute;
  transform: translate(0, -50%);
  top: 0;
  left: 0;
  height: 563px;
  z-index: 0;
  background: linear-gradient(
    180deg,
    rgba(250, 102, 102, 0) 19.76%,
    rgba(250, 102, 102, 0.342) 40.32%,
    rgba(164, 87, 138, 0.234) 54.55%,
    rgba(164, 87, 138, 0) 81.33%
  );

  ${media.tablet} {
    display: ${props => (props.hasDesktopGradient ? 'block' : 'none')};
  }
`

export default ({ hasDesktopGradient }: { hasDesktopGradient: boolean }) => (
  <TitleGradient hasDesktopGradient={hasDesktopGradient} />
)
