import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 0 auto 98px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 0 auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;
  height: auto;

  ${media.mobile} {
    width: 40px;
    right: 60px;
    top: 0px;
  }
  ${media.tablet} {
    width: 71px;
    right: 60px;
    top: -20px;
  }
`

const MiddleImage = styled(TopImage)`
  transform: translate(-50%, 0);
  ${media.mobile} {
    top: 50px;
    left: -3px;
    width: 45px;
  }
  ${media.tablet} {
    top: 20%;
    left: 2px;
    width: 85px;
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 50%);
    bottom: 60px;
    left: auto;
    right: 0;
    width: 60px;
  }
  ${media.tablet} {
    transform: translate(-50%, 50%);
    bottom: -25%;
    left: 50%;
    width: 140px;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    grapeJuice: file(relativePath: { regex: "/grape-juice.png/" }) {
      childImageSharp {
        fluid(maxWidth: 115) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    chemicalDyes: file(relativePath: { regex: "/ingredient-orbs/chemical-dyes.png/" }) {
      childImageSharp {
        fluid(maxWidth: 101) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    carrots: file(relativePath: { regex: "/carrots.png/" }) {
      childImageSharp {
        fluid(maxWidth: 87) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const othersMaxCharacters = 120

  const copy = {
    grummies: 'We only use organic fruit and vegetable juice for color in our Grummies.',
    others:
      'We see some pretty lame uses of artificial color out there in stores. It’s 2021, and yet some of the best-selling brands in America are still using ingredients like Blue 1, Yellow 5, and Caramel Color to make cheap gummies look pretty.\n\nWho wants to put something named Blue 1 in their body?',
  }

  const displayCopy = {
    grummies: copy.grummies,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient={false} />
      <Container>
        <H1>Color</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={390}
          openHeight={460}
          animationKey="color"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.grapeJuice.childImageSharp.fluid} aria-label="hidden" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.chemicalDyes.childImageSharp.fluid} aria-label="hidden" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.carrots.childImageSharp.fluid} aria-label="hidden" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>

              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
