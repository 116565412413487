import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'

import ImageCarousel from '../shared/ImageCarousel'
import { H2 } from '../shared/text'
import { media } from '../../styles/util'

import HeaderImage from '../../assets/img/text-headers/r-is-for.svg'
import Arrow from '../../assets/img/arrows/whats-inside-arrow.svg'
import MobileArrow from '../../assets/img/arrows/whats-inside-arrow-mobile.svg'

const Container = styled.div`
  position: relative;

  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;
  max-width: 794px;
  margin: 0 auto;

  ${media.mobile} {
    padding: 170px 0 160px 0;

    h2 {
      font-size: 16px;
      line-height: 24px;
      max-width: 250px;
      margin-bottom: 24px;
    }
  }
  ${media.tablet} {
    padding: 200px 100px 240px 100px;

    h2 {
      font-size: 25px;
      line-height: 30px;
      max-width: none;
      margin-bottom: 40px;
    }
  }
  ${media.desktop} {
    padding: 275px 0 240px 0;
  }
`

const HeaderImg = styled.img`
  width: 100%;

  ${media.mobile} {
    max-width: 289px;
    margin-bottom: 46px;
  }
  ${media.tablet} {
    max-width: 600px;
  }
  ${media.desktop} {
    max-width: 703px;
    margin-bottom: 62px;
  }
`

const ImageWrapper = styled.div`
  width: calc(100% - 16px);
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 16px 16px 0px ${props => props.theme.colors.purple};

  overflow: hidden;
  display: grid;
  place-items: center;
  height: auto;

  ${media.mobile} {
    border-radius: 42px;
  }
  ${media.tablet} {
    border-radius: 32px;
  }
`

const ArrowImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    bottom: 0;
    width: 96vw;
    min-width: initial;
  }
  ${media.desktop} {
    min-height: 188px;
    max-width: 674px;
  }
`

const MobileArrowImg = styled(ArrowImg)`
  ${media.mobile} {
    display: block;
    width: 100vw;
    bottom: 0;
  }
  ${media.tablet} {
    display: none;
  }
`

const BlobBaseStyling = styled.div`
  position: absolute;
  height: auto;
  z-index: 0;
`

const GreenBlobLargeImg = styled(BlobBaseStyling)`
  transform: translate(50%, 0);

  ${media.mobile} {
    width: 300px;
    top: 70px;
    right: -30px;
  }
  ${media.desktop} {
    width: 550px;
    top: 100px;
    right: 0;
  }
`

const OrangeLargeImg = styled(BlobBaseStyling)`
  transform: translate(-50%, 0);

  ${media.mobile} {
    width: 81vw;
    top: -170px;
    left: 50%;
  }
  ${media.tablet} {
    top: -300px;
  }
  ${media.desktop} {
    width: 800px;
    top: -360px;
    left: 45%;
  }
`

const PurpleBlobLargeImg = styled(BlobBaseStyling)`
  transform: translate(-50%, 0);

  ${media.mobile} {
    width: 350px;
    top: 80px;
    left: -20%;
  }
  ${media.tablet} {
    width: 350px;
    top: 140px;
    left: -6%;
  }
  ${media.desktop} {
    width: 650px;
    top: 120px;
    left: -10%;
  }
`

const BlueGreenBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 50px;
    top: 60px;
    left: 40%;
  }
  ${media.tablet} {
    top: 100px;
  }
  ${media.desktop} {
    width: 140px;
    top: 137px;
    left: 40%;
  }
`

const SmallBlueGreenBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 35px;
    top: 50%;
    right: 4%;
  }
  ${media.desktop} {
    width: 64px;
    right: -10%;
  }
`

const LargePurpleBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 32px;
    top: 210px;
    left: 20%;
  }
  ${media.tablet} {
    top: 260px;
  }
  ${media.desktop} {
    width: 62px;
    top: 365px;
    left: -5%;
  }
`

const SmallPurpleBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 20px;
    top: 205px;
    right: 27%;
  }
  ${media.tablet} {
    top: 280px;
    right: 22%;
  }
  ${media.desktop} {
    width: 35px;
    top: 390px;
    right: 18%;
  }
`

const LeftSmallPurpleBlob = styled(SmallPurpleBlobImg)`
  ${media.mobile} {
    top: 50%;
    right: auto;
    left: 5%;
    width: 28px;
  }
  ${media.desktop} {
    top: 50%;
    bottom: auto;
    right: auto;
    left: -10%;
    width: 35px;
  }
`

const MediumYellowBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 24px;
    top: 37%;
    left: 5%;
  }
  ${media.desktop} {
    width: 50px;
    left: -15%;
  }
`

const SmallYellowBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
    width: 35px;
    top: 280px;
    right: -2%;
  }
`

const BlueBlobImg = styled(BlobBaseStyling)`
  ${media.mobile} {
    width: 27px;
    top: 42%;
    right: 5%;
  }
  ${media.desktop} {
    width: 45px;
    top: 37%;
    right: -13%;
  }
`

const DesktopOnly = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 100%;
  }
`

const MobileOnly = styled.div`
  width: 100%;
  ${media.tablet} {
    display: none;
  }
`

const query = graphql`
  query {
    greenBlobLarge: file(relativePath: { regex: "/whats-inside/green-blob-large.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    orangeBlobLarge: file(relativePath: { regex: "/whats-inside/orange-blob-large.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleBlobLarge: file(relativePath: { regex: "/whats-inside/purple-blob-large.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    blueGreenBlob: file(relativePath: { regex: "/whats-inside/blue-green-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 140) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleBlob: file(relativePath: { regex: "/whats-inside/purple-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 62) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yellowBlob: file(relativePath: { regex: "/whats-inside/yellow-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 35) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    blueBlob: file(relativePath: { regex: "/whats-inside/blue-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 45) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image1: file(relativePath: { regex: "/whats-inside/carousel/image-1.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image2: file(relativePath: { regex: "/whats-inside/carousel/image-2.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image3: file(relativePath: { regex: "/whats-inside/carousel/image-3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image1mobile: file(relativePath: { regex: "/whats-inside/carousel/image-1-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image2mobile: file(relativePath: { regex: "/whats-inside/carousel/image-2-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image3mobile: file(relativePath: { regex: "/whats-inside/carousel/image-3-mobile.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const images = useStaticQuery(query)
  const {
    greenBlobLarge,
    orangeBlobLarge,
    purpleBlobLarge,
    blueGreenBlob,
    purpleBlob,
    yellowBlob,
    blueBlob,
    image1,
    image2,
    image3,
    image1mobile,
    image2mobile,
    image3mobile,
  } = images

  const carouselImages = [
    {
      desktop: image1.childImageSharp.fluid,
      mobile: image1mobile.childImageSharp.fluid,
      alt: 'Turmeric and ACV bottles',
    },
    {
      desktop: image2.childImageSharp.fluid,
      mobile: image2mobile.childImageSharp.fluid,
      alt: 'Grummies bottles on side',
    },
    {
      desktop: image3.childImageSharp.fluid,
      mobile: image3mobile.childImageSharp.fluid,
      alt: 'Ashwagandha gummies',
    },
  ]

  useEffect(() => {
    gsap.to('.whats-inside__blob', {
      duration: 'random(4, 6)',
      stagger: 0.25,
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(10, 16)',
    })
    gsap.to('.whats-inside__blob--large', {
      duration: 'random(8, 10)',
      stagger: 0.25,
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(20, 34)',
    })
  }, [])

  return (
    <>
      <PurpleBlobLargeImg className="whats-inside__blob--large">
        <Img fluid={purpleBlobLarge.childImageSharp.fluid} aria-hidden="true" />
      </PurpleBlobLargeImg>
      <OrangeLargeImg className="whats-inside__blob--large">
        <Img fluid={orangeBlobLarge.childImageSharp.fluid} aria-hidden="true" />
      </OrangeLargeImg>
      <GreenBlobLargeImg className="whats-inside__blob--large">
        <Img fluid={greenBlobLarge.childImageSharp.fluid} aria-hidden="true" />
      </GreenBlobLargeImg>

      <Container>
        <HeaderImg src={HeaderImage} alt="R is for real. Nothing fake." />

        <DesktopOnly>
          <ImageCarousel>
            {carouselImages.map(image => (
              <ImageWrapper key={image.alt}>
                <Img
                  fluid={image.desktop}
                  alt={image.alt}
                  style={{ height: '100%', width: '100%' }}
                />
              </ImageWrapper>
            ))}
          </ImageCarousel>
        </DesktopOnly>
        <MobileOnly>
          <ImageCarousel>
            {carouselImages.map(image => (
              <ImageWrapper key={image.alt}>
                <Img
                  fluid={image.mobile}
                  alt={image.alt}
                  style={{ height: '100%', width: '100%' }}
                />
              </ImageWrapper>
            ))}
          </ImageCarousel>
        </MobileOnly>

        <H2>
          The gummy industry is full of cheap, unhealthy products stuffed with corn syrup,
          artificial flavors, too much sugar, and toxic food dyes.
        </H2>
        <H2>
          That’s why so many popular retail gummies cost $12. All you’re getting is a lump of sugar.
        </H2>
        <H2>
          We gathered some of the best minds in gummies to try to fix this problem, and spent two
          years turning the whole process inside out.
        </H2>
        <H2>
          We took every cheap artificial ingredient used by the gummy industry, and meticulously
          replaced each one with real, healthy equivalents.
        </H2>
        <H2>
          For transparency, we built this table showing every type of ingredient needed to make a
          gummy, so you can compare the real good stuff in Grummies with the gross artificial stuff
          everyone else uses.
        </H2>

        <ArrowImg src={Arrow} aria-hidden />
        <MobileArrowImg src={MobileArrow} aria-hidden />

        <BlueGreenBlobImg whats-inside__blob="blob">
          <Img fluid={blueGreenBlob.childImageSharp.fluid} aria-hidden="true" />
        </BlueGreenBlobImg>
        <SmallBlueGreenBlobImg className="whats-inside__blob">
          <Img fluid={blueGreenBlob.childImageSharp.fluid} aria-hidden="true" />
        </SmallBlueGreenBlobImg>
        <LargePurpleBlobImg className="whats-inside__blob">
          <Img fluid={purpleBlob.childImageSharp.fluid} aria-hidden="true" />
        </LargePurpleBlobImg>
        <SmallPurpleBlobImg className="whats-inside__blob">
          <Img fluid={purpleBlob.childImageSharp.fluid} aria-hidden="true" />
        </SmallPurpleBlobImg>
        <LeftSmallPurpleBlob className="whats-inside__blob">
          <Img fluid={purpleBlob.childImageSharp.fluid} aria-hidden="true" />
        </LeftSmallPurpleBlob>
        <MediumYellowBlobImg className="whats-inside__blob">
          <Img fluid={yellowBlob.childImageSharp.fluid} aria-hidden="true" />
        </MediumYellowBlobImg>
        <SmallYellowBlobImg className="whats-inside__blob">
          <Img fluid={yellowBlob.childImageSharp.fluid} aria-hidden="true" />
        </SmallYellowBlobImg>
        <BlueBlobImg className="whats-inside__blob">
          <Img fluid={blueBlob.childImageSharp.fluid} aria-hidden="true" />
        </BlueBlobImg>
      </Container>
    </>
  )
}
