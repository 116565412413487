import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 54px auto 98px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 54px auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;
  ${media.mobile} {
    width: 45px;
    height: auto;
    right: 60px;
    top: 0;
  }
  ${media.tablet} {
    width: 75px;
    height: auto;
    right: 60px;
    top: -16px;
  }
`

const MiddleImage = styled(TopImage)`
  ${media.mobile} {
    top: 20%;
    left: 2px;
    width: 57px;
    transform: translate(-50%, 0);
  }
  ${media.tablet} {
    top: 20%;
    left: 2px;
    width: 87px;
    transform: translate(-50%, 0);
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 50%);
    width: 55px;
    top: auto;
    bottom: 70px;
    left: auto;
    right: 0;
  }
  ${media.tablet} {
    transform: translate(-50%, 50%);
    width: 97px;
    top: auto;
    bottom: 20px;
    right: auto;
    left: 50%;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    spirulina: file(relativePath: { regex: "/ingredients/spirulina.png/" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    elderberry: file(relativePath: { regex: "/ingredients/elderberry-vertical.png/" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    acv: file(relativePath: { regex: "/ingredients/acv.png/" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const grummiesMaxCharacters = 116
  const othersMaxCharacters = 119

  const copy = {
    grummies:
      'Our Grummies use premium active ingredient superfoods, sourced from high-quality raw material suppliers like Sabinsa, maker of the patented Curcumin C3 Complex in our Turmeric Grummies.\n\nWe use the maximum dosage capable of fitting into a 3 gram gummy (which varies by superfood). We always choose USDA Organic superfoods like our ACV and elderberry if possible.',
    others:
      'Many top gummies use secondary “filler” active ingredients to distract from the low dosages of the costlier main active ingredients they market against.\n\nThey also choose cheap actives from unknown manufacturers, and rarely have organic certifications or standardized ingredients.  When you buy gummies for $10, you get what you paid for.',
  }

  const displayCopy = {
    grummies: open ? copy.grummies : `${copy.grummies.slice(0, grummiesMaxCharacters)}...`,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient={false} />
      <Container>
        <H1>Active ingredients</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={380}
          openHeight={550}
          animationKey="activeIngredients"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.spirulina.childImageSharp.fluid} aria-label="hidden" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.elderberry.childImageSharp.fluid} aria-label="hidden" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.acv.childImageSharp.fluid} aria-label="hidden" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>

              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
