import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import AboveTheFold from './AboveTheFold'
import ActiveIngredients from './ActiveIngredients'
import Sweeteners from './Sweeteners'
import GummyBase from './GummyBase'
import Flavor from './Flavor'
import Color from './Color'
import Stabilizers from './Stabilizers'
import BottomSection from './BottomSection'

import { media } from '../../styles/util'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Container = styled.div`
  position: relative;
  width: 100%;
`

const GradientTop = styled.div`
  background: linear-gradient(161.63deg, #99d4ff 8.19%, rgba(255, 255, 255, 0) 67.7%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000px;
  z-index: 0;
`

const LogoWrapper = styled(Link)`
  &:hover {
    animation: ${wiggle} 250ms;
  }

  position: absolute;
  z-index: 30;

  ${media.mobile} {
    top: 75px;
    left: 21px;
    width: 62px;
    height: 62px;
  }
  ${media.tablet} {
    top: 75px;
    left: 80px;
    width: 100px;
    height: auto;
  }
  ${media.desktop} {
    top: 45px;
    left: 80px;
    width: 144px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const { logo } = useStaticQuery(query)

  return (
    <Container>
      <GradientTop />
      <LogoWrapper to="/">
        <Img
          fluid={logo.childImageSharp.fluid}
          alt="Grummies logo"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </LogoWrapper>
      <AboveTheFold />
      <ActiveIngredients />
      <Sweeteners />
      <GummyBase />
      <Flavor />
      <Color />
      <Stabilizers />
      <BottomSection />
    </Container>
  )
}
