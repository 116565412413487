import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GridGroup from './GridGroup'
import TitleGradient from './TitleGradient'
import { H1, H2, P } from '../shared/text'
import { media } from '../../styles/util'

const RelativeWrapper = styled.div`
  // To position TitleGradient
  width: 100%;
  position: relative;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  max-width: 1000px;

  ${media.mobile} {
    grid-row-gap: 32px;
    margin: 0 auto 40px;
  }
  ${media.tablet} {
    grid-row-gap: 54px;
    margin: 0 auto 130px;
  }
`

const TopImage = styled.div`
  box-sizing: content-box;
  position: absolute;
  height: auto;

  ${media.mobile} {
    width: 40px;
    right: 60px;
    top: -2px;
  }
  ${media.tablet} {
    width: 80px;
    right: 60px;
    top: -20px;
  }
`

const MiddleImage = styled(TopImage)`
  transform: translate(-50%, 0);

  ${media.mobile} {
    top: 50px;
    left: -2px;
    width: 30px;
  }
  ${media.tablet} {
    top: 20%;
    left: -1px;
    width: 48px;
  }
`

const BottomImage = styled(MiddleImage)`
  ${media.mobile} {
    transform: translate(50%, 0);
    top: auto;
    bottom: 50px;
    left: auto;
    right: 0;
    width: 40px;
  }
  ${media.tablet} {
    transform: translate(-50%, 0);
    top: auto;
    bottom: -20px;
    left: 50%;
    width: 70px;
  }
`

const LogoWrapper = styled.div`
  height: auto;
  ${media.mobile} {
    width: 50px;
  }
  ${media.tablet} {
    width: 85px;
  }
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sunflower: file(relativePath: { regex: "/sunflower.png/" }) {
      childImageSharp {
        fluid(maxWidth: 115) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    droplet: file(relativePath: { regex: "/droplet.png/" }) {
      childImageSharp {
        fluid(maxWidth: 101) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lemon: file(relativePath: { regex: "/lemon.png/" }) {
      childImageSharp {
        fluid(maxWidth: 87) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(query)
  const [open, setOpen] = useState(false)

  const grummiesMaxCharacters = 58
  const othersMaxCharacters = 120

  const copy = {
    grummies: `Stabilizers help make a gummy reliable.\n\nWe use citric acid from fruits to balance our Grummies’ acidity. We use sodium citrate, a salt from citric acid that meets Whole Foods’ Food Ingredient Quality Standards, to maintain their shape. We us organic carnauba wax and organic sunflower oil to keep our Grummies from sticking.`,
    others: `Leading retail gummies use all kinds of intense chemicals to make gummies gel, dry and keep them from losing their shape or acidity.\n\nHere are a few: carrageenan, sodium carbonate, fumaric acid, dicalcium phosphate, silica, sodium potassium tartrate, sodium polyphosphate.\n\nThat's a lot of fake stuff you can't pronounce.`,
  }

  const displayCopy = {
    grummies: open ? copy.grummies : `${copy.grummies.slice(0, grummiesMaxCharacters)}...`,
    others: open ? copy.others : `${copy.others.slice(0, othersMaxCharacters)}...`,
  }

  return (
    <RelativeWrapper>
      <TitleGradient hasDesktopGradient />
      <Container>
        <H1>Stabilizers</H1>
        <GridGroup
          open={open}
          setOpen={setOpen}
          closedHeight={330}
          openHeight={550}
          animationKey="stabilizers"
          leftContent={
            <>
              <TopImage>
                <Img fluid={data.sunflower.childImageSharp.fluid} aria-label="hidden" />
              </TopImage>
              <MiddleImage>
                <Img fluid={data.droplet.childImageSharp.fluid} aria-label="hidden" />
              </MiddleImage>
              <BottomImage>
                <Img fluid={data.lemon.childImageSharp.fluid} aria-label="hidden" />
              </BottomImage>
              <LogoWrapper>
                <Img fluid={data.logo.childImageSharp.fluid} alt="Grummies logo" />
              </LogoWrapper>

              <P>{displayCopy.grummies}</P>
            </>
          }
          rightContent={
            <>
              <H2>{`Best-selling fake\ngummies`}</H2>
              <P>{displayCopy.others}</P>
            </>
          }
        />
      </Container>
    </RelativeWrapper>
  )
}
