import React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../layouts'
import WhatsInside from '../components/WhatsInside'
import SEO from '../components/shared/SEO'

const WhatsInsidePage = (props: PageProps) => {
  const { location } = props
  return (
    <>
      <SEO
        title="Real Ingredients"
        description="Grummies are real superfood gummies, made with powerful superfoods, organic sweeteners, flavors from actual fruit, and certifications that count."
        keywords={[
          'superfood vitamins',
          'gummy vitamins',
          'health gummies',
          'superfoods',
          'adult gummies',
          'adult vitamins',
        ]}
      />
      <Layout location={location}>
        <WhatsInside />
      </Layout>
    </>
  )
}

export default WhatsInsidePage
